export const routes = [
  {
    path: '/:not_found(.*)',
    name: '/[...not_found]',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/[...not_found].vue'),
    /* no children */
    meta: {
      "public": true,
      "title": "document.notFound"
    }
  },
  {
    path: '/admin',
    /* internal name: '/admin' */
    /* no component */
    children: [
      {
        path: 'app',
        /* internal name: '/admin/app' */
        /* no component */
        children: [
          {
            path: '',
            name: '/admin/app/',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/app/index.vue'),
            /* no children */
          },
          {
            path: 'organizers',
            name: '/admin/app/organizers',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/app/organizers.vue'),
            /* no children */
            meta: {
              "layout": "AdminApp.layout"
            }
          }
        ],
      },
      {
        path: 'event/:eventId',
        /* internal name: '/admin/event.[eventId]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/admin/event.[eventId]/',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/index.vue'),
            /* no children */
          },
          {
            path: 'advanced',
            name: '/admin/event.[eventId]/advanced',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/advanced.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'b2b-group-params',
            name: '/admin/event.[eventId]/b2b-group-params',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/b2b-group-params.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'charity-group-params',
            name: '/admin/event.[eventId]/charity-group-params',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/charity-group-params.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'dashboard',
            name: '/admin/event.[eventId]/dashboard',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/dashboard.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'discount-code/:discountCodeId?',
            name: '/admin/event.[eventId]/discount-code.[[discountCodeId]]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/discount-code.[[discountCodeId]].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'discount-codes',
            name: '/admin/event.[eventId]/discount-codes',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/discount-codes.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'event-participation/:organizationType/:organizationId',
            name: '/admin/event.[eventId]/event-participation.[organizationType].[organizationId]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/event-participation.[organizationType].[organizationId].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'event-participations/:organizationType',
            name: '/admin/event.[eventId]/event-participations.[organizationType]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/event-participations.[organizationType].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'event-tickets',
            name: '/admin/event.[eventId]/event-tickets',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/event-tickets.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'payment-methods/:organizationType',
            name: '/admin/event.[eventId]/payment-methods.[organizationType]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/payment-methods.[organizationType].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-categories/:organizationType',
            name: '/admin/event.[eventId]/sales-categories.[organizationType]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-categories.[organizationType].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-discount-code/:organizationType/:salesDiscountCodeId?',
            name: '/admin/event.[eventId]/sales-discount-code.[organizationType].[[salesDiscountCodeId]]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-discount-code.[organizationType].[[salesDiscountCodeId]].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-discount-codes/:organizationType',
            name: '/admin/event.[eventId]/sales-discount-codes.[organizationType]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-discount-codes.[organizationType].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-order/:orderId',
            name: '/admin/event.[eventId]/sales-order.[orderId]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-order.[orderId].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-orders/:organizationType',
            name: '/admin/event.[eventId]/sales-orders.[organizationType]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-orders.[organizationType].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-product/:organizationType/:salesProductId?',
            name: '/admin/event.[eventId]/sales-product.[organizationType].[[salesProductId]]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-product.[organizationType].[[salesProductId]].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-products/:organizationType',
            name: '/admin/event.[eventId]/sales-products.[organizationType]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-products.[organizationType].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          }
        ],
      },
      {
        path: 'organizer/:organizerId',
        /* internal name: '/admin/organizer.[organizerId]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/admin/organizer.[organizerId]/',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/index.vue'),
            /* no children */
          },
          {
            path: 'events',
            name: '/admin/organizer.[organizerId]/events',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/events.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'events/kind/:modelKindId/new',
            name: '/admin/organizer.[organizerId]/events.kind.[modelKindId].new',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/events.kind.[modelKindId].new.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'model-fields',
            name: '/admin/organizer.[organizerId]/model-fields',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/model-fields.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'model-fields/:model',
            name: '/admin/organizer.[organizerId]/model-fields.[model]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/model-fields.[model].vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'organization/:type/:organizationId',
            name: '/admin/organizer.[organizerId]/organization.[type].[organizationId]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/organization.[type].[organizationId].vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/backoffice',
    name: '/backoffice',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/backoffice.vue'),
    alias: ["/","/onboarding","/admin/"],
    /* no children */
    meta: {
      "public": true
    }
  },
  {
    path: '/event/:eventId',
    /* internal name: '/event.[eventId]' */
    /* no component */
    children: [
      {
        path: 'subscribe/:organizationId?',
        name: '/event.[eventId]/subscribe.[[organizationId]]',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/event.[eventId]/subscribe.[[organizationId]].vue'),
        /* no children */
        meta: {
          "layout": "Event.layout",
          "public": true,
          "display_event_name": true,
          "b2b_help_chat": true
        }
      }
    ],
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "Event.layout",
      "title": "document.login",
      "b2b_help_chat": true,
      "public": true
    }
  },
  {
    path: '/logout',
    name: '/logout',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/logout.vue'),
    /* no children */
    meta: {
      "title": "public.user.logout.title",
      "public": true
    }
  }
]
